// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
/** custome OB colors **/
$ion-color-ob-tablenumber-bg: #f0f7f9;
$ion-color-ob-tablenumber: #01426a;

:root {
  /** primary **/
  --ion-color-primary: #3880ff;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #fff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;

  /** secondary **/
  --ion-color-secondary: #0cd1e8;
  --ion-color-secondary-rgb: 12, 209, 232;
  --ion-color-secondary-contrast: #fff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #0bb8cc;
  --ion-color-secondary-tint: #24d6ea;

  /** tertiary **/
  --ion-color-tertiary: #7044ff;
  --ion-color-tertiary-rgb: 112, 68, 255;
  --ion-color-tertiary-contrast: #fff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #633ce0;
  --ion-color-tertiary-tint: #7e57ff;

  /** success **/
  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #fff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;

  /** warning **/
  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #fff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;

  /** danger **/
  --ion-color-danger: #f04141;
  --ion-color-danger-rgb: 245, 61, 61;
  --ion-color-danger-contrast: #fff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #fff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #fff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /** custom split-main color **/
  --ion-color-split-main: #ee754f;
  --ion-color-split-main-rgb: 238, 117, 79;
  --ion-color-split-main-contrast: #fff;
  --ion-color-split-main-contrast-rgb: 0, 0, 0;
  --ion-color-split-main-shade: #d16746;
  --ion-color-split-main-tint: #f08361;

  /** custom split-secondary color **/
  --ion-color-split-secondary: #012c40;
  --ion-color-split-secondary-rgb: 1, 44, 64;
  --ion-color-split-secondary-contrast: #fff;
  --ion-color-split-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-split-secondary-shade: #012738;
  --ion-color-split-secondary-tint: #1a4153;

  /** custom split-secondary color **/
  --ion-color-split-button: #012c40;
  --ion-color-split-button-rgb: 1, 44, 64;
  --ion-color-split-button-contrast: #fff;
  --ion-color-split-button-contrast-rgb: 255, 255, 255;
  --ion-color-split-button-shade: #012738;
  --ion-color-split-button-tint: #1a4153;

  /** custom split point card check **/
  --ion-color-split-points: #fff;
  --ion-color-split-points-rgb: 16, 220, 96;
  --ion-color-split-points-contrast: #fff;
  --ion-color-split-points-contrast-rgb: 255, 255, 255;
  --ion-color-split-points-shade: #0ec254;
  --ion-color-split-points-tint: #28e070;

  /** Orderbird **/
  --orderbird-color-blue: #00a3e0;
  --orderbird-color-darkblue: #01426a;
  --orderbird-color-highlight-yellow: #fecc00;
  --orderbird-color-danger: #c05145;
  --orderbird-color-greensuccess: #36cc53;
  --orderbird-color-graylight: #f2f1ef;
  --orderbird-color-greycoal: #323435;
  --orderbird-color-graymid: #bdbdbd;
  --orderbird-color-greydark: #767676;
  --orderbird-color-darkgrey-secondary: #414445;
  --orderbird-color-midgrey: #bdbcbc;
  --orderbird-color-lightgrey-two: #f8f8f8;
  --orderbird-color-lightgrey: #f4f4f4;
  --orderbird-color-whitelight: #c8eacf;
  --orderbird-color-lightgreen: #c8eacf66;
  --orderbird-color-white: #fff;
  --orderbird-color-black: #000;
  --orderbird-color-lightwhite: #f2f1ef;
  --orderbird-color-header-text: #323435;
  --orderbird-color-text: #292a2c;
  --orderbird-color-orangedark: #f07723;
  --orderbird-color-boxwhite: #d1d1d1;
  --orderbird-font-family: neue-haas-grotesk-display, sans-serif;
  --orderbird-color-status-green: #369040;
  --ion-color-orderbird: #00a3e0;
  --ion-color-orderbird-rgb: 0, 0, 0;
  --ion-color-orderbird-contrast: #fff;
  --ion-color-orderbird-contrast-rgb: #fff;
  --ion-color-orderbird-shade: #00a3e0;
  --ion-color-orderbird-tint: #00a3e0;
  --ion-color-orderbird-blue-background: #cee2eb;

  $loading-ios-background: transparent;
  $loading-md-background: $loading-ios-background;
  $loading-wp-background: $loading-ios-background;
  $loading-md-box-shadow: none;
}
